import { trpc } from '@/lib/trpc/trpc';
import Link from 'next/link';
import Image from 'next/image';
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuItem } from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';
import { IconUser, IconMenu2, IconLogout } from '@tabler/icons-react';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet';
import { UserContext } from '@/contexts/UserContext';
import { useContext, useState } from 'react';
export default function NavBar() {
  const [openMenuSheet, setOpenMenuSheet] = useState(false);
  const userContext = useContext(UserContext);
  const userData = trpc.User.GetUser.useQuery({
    id: userContext.claims?.sub
  });
  return <header className='flex items-center justify-between px-4 py-3' data-sentry-component="NavBar" data-sentry-source-file="navbar.tsx">
      <div className='flex items-center gap-4'>
        <div className='md:hidden'>
          <div className='flex items-center gap-4'>
            <Sheet open={openMenuSheet} onOpenChange={() => setOpenMenuSheet(!openMenuSheet)} data-sentry-element="Sheet" data-sentry-source-file="navbar.tsx">
              <SheetTrigger data-sentry-element="SheetTrigger" data-sentry-source-file="navbar.tsx">
                <IconMenu2 onClick={() => setOpenMenuSheet(!openMenuSheet)} data-sentry-element="IconMenu2" data-sentry-source-file="navbar.tsx" />
              </SheetTrigger>
              <SheetContent side={'left'} className='w-full bg-slate-600' data-sentry-element="SheetContent" data-sentry-source-file="navbar.tsx">
                <SheetHeader data-sentry-element="SheetHeader" data-sentry-source-file="navbar.tsx">
                  <SheetTitle data-sentry-element="SheetTitle" data-sentry-source-file="navbar.tsx">Air Reserve Menu</SheetTitle>
                </SheetHeader>
                <div className='grid grid-cols-1 p-1'>
                  <div className='p-2'>
                    <Link onClick={() => setOpenMenuSheet(!openMenuSheet)} href='/calendar' className='text-lg font-medium underline-offset-4 hover:underline' prefetch={false} data-sentry-element="Link" data-sentry-source-file="navbar.tsx">
                      Calendar
                    </Link>
                  </div>
                  <div className='p-2'>
                    <Link onClick={() => setOpenMenuSheet(!openMenuSheet)} href='/flightlog' className='text-lg font-medium underline-offset-4 hover:underline' prefetch={false} data-sentry-element="Link" data-sentry-source-file="navbar.tsx">
                      Flight Log
                    </Link>
                  </div>
                  <div className='p-2'>
                    <Link onClick={() => setOpenMenuSheet(!openMenuSheet)} href='/statement' className='text-lg font-medium underline-offset-4 hover:underline' prefetch={false} data-sentry-element="Link" data-sentry-source-file="navbar.tsx">
                      Your Statement
                    </Link>
                  </div>
                  <div className='p-2'>
                    <Link onClick={() => setOpenMenuSheet(!openMenuSheet)} href='/finances' className='text-lg font-medium underline-offset-4 hover:underline' prefetch={false} data-sentry-element="Link" data-sentry-source-file="navbar.tsx">
                      Finances
                    </Link>
                  </div>
                  {userContext.scopes?.includes('global:admin') ? <div className='p-2'>
                      <Link onClick={() => setOpenMenuSheet(!openMenuSheet)} href='/aircraftsettings' className='text-lg font-medium underline-offset-4 hover:underline' prefetch={false}>
                        Aircraft Settings
                      </Link>
                    </div> : <></>}
                </div>
              </SheetContent>
            </Sheet>
          </div>
        </div>
        <Link href='/' className='flex items-center gap-2' prefetch={false} data-sentry-element="Link" data-sentry-source-file="navbar.tsx">
          <Image className='dark:hidden' src={'/light-NavLogo.png'} width={150} height={100} alt='Logo' priority={true} data-sentry-element="Image" data-sentry-source-file="navbar.tsx" />
          <Image className='hidden dark:block' src={'/dark-NavLogo.png'} width={150} height={100} alt='Logo' priority={true} data-sentry-element="Image" data-sentry-source-file="navbar.tsx" />
        </Link>
        <nav className='xs:hidden hidden items-center gap-4 md:flex'>
          <Link href='/calendar' className='text-sm font-medium text-muted-foreground hover:text-foreground' prefetch={false} data-sentry-element="Link" data-sentry-source-file="navbar.tsx">
            Calendar
          </Link>
          <Link href='/flightlog' className='text-sm font-medium text-muted-foreground hover:text-foreground' prefetch={false} data-sentry-element="Link" data-sentry-source-file="navbar.tsx">
            Flight Log
          </Link>
          <Link href='/statement' className='text-sm font-medium text-muted-foreground hover:text-foreground' prefetch={false} data-sentry-element="Link" data-sentry-source-file="navbar.tsx">
            Your Statement
          </Link>
          <Link href='/finances' className='text-sm font-medium text-muted-foreground hover:text-foreground' prefetch={false} data-sentry-element="Link" data-sentry-source-file="navbar.tsx">
            Finances
          </Link>
          {userContext.scopes?.includes('global:admin') ? <Link href='/aircraftsettings' className='text-sm font-medium text-muted-foreground hover:text-foreground' prefetch={false}>
              Aircraft Settings
            </Link> : <></>}
        </nav>
      </div>
      <div className='flex items-center gap-4'>
        <DropdownMenu data-sentry-element="DropdownMenu" data-sentry-source-file="navbar.tsx">
          <DropdownMenuTrigger asChild data-sentry-element="DropdownMenuTrigger" data-sentry-source-file="navbar.tsx">
            <Button variant='ghost' size='icon' className='rounded-full' data-sentry-element="Button" data-sentry-source-file="navbar.tsx">
              <Avatar className='h-8 w-8' data-sentry-element="Avatar" data-sentry-source-file="navbar.tsx">
                <AvatarImage src={userData.data?.avatar || ''} alt='@username' data-sentry-element="AvatarImage" data-sentry-source-file="navbar.tsx" />
                <AvatarFallback data-sentry-element="AvatarFallback" data-sentry-source-file="navbar.tsx">
                  {userData.data ? userData.data.avatar ? '' : userData.data.profile?.givenName && userData.data.profile.familyName ? `${(userData.data.profile?.givenName).substring(0, 1)}${userData.data.profile.familyName.substring(0, 1)}` : userData.data.name.substring(0, 1) : userContext.claims?.name?.substring(0, 1)}
                </AvatarFallback>
              </Avatar>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end' className='w-56' data-sentry-element="DropdownMenuContent" data-sentry-source-file="navbar.tsx">
            <DropdownMenuLabel data-sentry-element="DropdownMenuLabel" data-sentry-source-file="navbar.tsx">
              {userContext.claims?.name || 'Name not Set'}
            </DropdownMenuLabel>
            <DropdownMenuSeparator data-sentry-element="DropdownMenuSeparator" data-sentry-source-file="navbar.tsx" />
            <DropdownMenuItem asChild data-sentry-element="DropdownMenuItem" data-sentry-source-file="navbar.tsx">
              <Link href='/user' data-sentry-element="Link" data-sentry-source-file="navbar.tsx">
                <IconUser className='mr-2 h-4 w-4' data-sentry-element="IconUser" data-sentry-source-file="navbar.tsx" />
                Profile
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem asChild data-sentry-element="DropdownMenuItem" data-sentry-source-file="navbar.tsx">
              <div onClick={() => window.location.assign('api/logto/sign-out')}>
                <IconLogout className='mr-2 h-4 w-4' data-sentry-element="IconLogout" data-sentry-source-file="navbar.tsx" />
                Sign-Out
              </div>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </header>;
}